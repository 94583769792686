
import Swiper from "swiper";
export default {
    data() {
        return {
            // bannerPar: [{
            //     title: 'Ex d IIC T4 Gb',
            //     dec: '防爆等级'
            // },
            // {
            //     title: 'IP67',
            //     dec: '防护等级'
            // },
            // {
            //     title: '0-1.5m/s',
            //     dec: '行驶速度'
            // },
            // {
            //     title: '≤30°',
            //     dec: '爬坡能力'
            // }],
            // bannerList: [{
            //     name: '防爆化工挂轨巡检机器人',
            //     dec: '提供表计识别读数、设备红外测温、跑冒滴漏检测、易燃有毒气体检测、粉尘环境监测、皮带异常监测等功能，适用于各类石油、化工、煤矿等易燃易爆、有毒有害的采集站、运输线、化工厂、存储区及井下的智能巡检业务场景。轨道系统依项目场地实际情况设计安装，机器人沿轨道行走，实现对项目场地待巡检对象的全面覆盖。'
            // },
            // {
            //     name: '应用场景',
            //     dec: '防爆化工挂轨巡检机器人适用于各类石油、化工、煤矿等易燃易爆、有毒有害的采集站、运输线、化工厂、存储区及井下的智能巡检业务场景。'
            // }],
            // funPar: [{
            //     name: '表计识别读数',
            //     dec: '通过AI视觉技术读取各类仪器仪表数据，采集到的数据超过阈值则产生预警。',
            //     img: this.$store.state.pageset.pcshow ? require('./img/4_1p.jpg') : require('./img/mobile/4_1p.jpg')
            // },
            // {
            //     name: '跑冒滴漏监测',
            //     dec: '通过AI视觉识别技术等手段进行跑冒滴漏检测，能够显示泄漏位置的坐标并预警。',
            //     img: this.$store.state.pageset.pcshow ? require('./img/4_2p.jpg') : require('./img/mobile/4_2p.jpg')
            // },
            // {
            //     name: '无人自主巡检',
            //     dec: '可实现自动巡检、手动巡检、异常巡检等巡检方式，沿着轨道自动巡检。',
            //     img: this.$store.state.pageset.pcshow ? require('./img/5_1p.jpg') : require('./img/mobile/5_1p.jpg')
            // },
            // {
            //     name: '智能避障功能',
            //     dec: '若载避障传感器，通过激光雷达、防碰撞传感器实现安全运行、避障及预警。',
            //     img: this.$store.state.pageset.pcshow ? require('./img/5_2p.jpg') : require('./img/mobile/5_2p.jpg')
            // },
            // ],
            // // 轮播
            // swiperList: [{
            //     name: '设备红外测温',
            //     dec: '搭载红外热成像仪，能够对设备表面温度进行采集，对设备温度异常进行预警',
            //     img: this.$store.state.pageset.pcshow ? require('./img/6_1p.jpg') : require('./img/mobile/6_1p.jpg')
            // },
            // {
            //     name: '环境气体监测',
            //     dec: '可对氧气、一氧化碳、可燃性气体、硫化氢等易燃易爆、有毒有害气体进行实时监测',
            //     img: this.$store.state.pageset.pcshow ? require('./img/6_2p.jpg') : require('./img/mobile/6_2p.jpg')
            // }],
            // parList: {
            //     left: [{
            //         name: '外形尺寸 (长×宽×高)',
            //         value: `640mm×300mm
            //         ×460mm`
            //     }, {
            //         name: '防爆等级',
            //         value: 'Ex d IIB T4 Gb'
            //     }, {
            //         name: '工作环境温度',
            //         value: '-20°C~+60°C'
            //     }, {
            //         name: '爬坡能力',
            //         value: '≤30°'
            //     }, {
            //         name: '充电时间',
            //         value: '≤3小时'
            //     }, {
            //         name: '定位方式',
            //         value: '里程计+RFID校准'
            //     }],
            //     right: [
            //         {
            //             name: '整备质量',
            //             value: '≤80kg'
            //         },
            //         {
            //             name: '防护等级',
            //             value: 'IP67'
            //         },
            //         {
            //             name: '行走速度',
            //             value: `0-1.5m/s，
            //             速度可调`
            //         },
            //         {
            //             name: '刹车距离',
            //             value: '600mm'
            //         },
            //         {
            //             name: '续航时间',
            //             value: '≥6公里'
            //         },
            //         {
            //             name: '重复导航定位精度',
            //             value: '≤1cm'
            //         },
            //     ]
            // },

            // elmentList:
            // [
            //     {
            //         pcElementId:70,
            //         phoneElementId: 81,
            //         descList: [
            //             {
            //                 descName: 'Explosion-proof Track-hanging Inspection Robot',
            //                 sort: 1
            //             }, {
            //                 descName: 'Ex d IIC T4 Gb',
            //                 sort: 2
            //             }, {
            //                 descName: 'IP67',
            //                 sort: 3
            //             }, {
            //                 descName: '1.5m/s',
            //                 sort: 4
            //             }, {
            //                 descName: '≤30°',
            //                 sort: 5
            //             },
            //             {
            //                 descName: 'Explosion-proof Grade',
            //                 sort: 6
            //             }, {
            //                 descName: 'Protection Level',
            //                 sort: 7
            //             }, {
            //                 descName: 'Gradeability',
            //                 sort: 8
            //             }, {
            //                 descName: 'Running Speed',
            //                 sort: 9
            //             }],
            //         type: 'image',
            //         sort: 1
            //     },
            //     {
            //         pcElementId: 71,
            //         phoneElementId: 82,
            //         descList: [
            //             {
            //                 descName: 'Explosion-proof Track-hanging Inspection Robot',
            //                 sort: 1
            //             }, {
            //                 descName: 'Providing meter identification reading, equipment infrared temperature measurement, leakage detection, flammable toxic gas detection, dust environment monitoring, belt anomaly monitoring and other functions, suitable for intelligent inspection business scenarios of various flammable, explosive, toxic and harmful collection stations, transportation lines, chemical plants, storage areas and underground in petroleum, chemical,coal mines and others. The track system is designed and installed according to the actual situation of the project site, and the robot walks along the track to achieve full coverage of the objects to be inspected on the project site.',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 2
            //     },
            //     {
            //         pcElementId: 72,
            //         phoneElementId: 83,
            //         descList: [
            //             {
            //                 descName: 'Application Scenarios',
            //                 sort: 1
            //             }, {
            //                 descName: 'Explosion-proof Track-hang Inspection Robot can be applied in intelligent inspection business scenarios of various flammable, explosive, toxic and harmful collection stations, transportation lines, chemical plants, storage areas and underground in petroleum, chemical,coal mines and others.',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 3
            //     },
            //     {
            //         pcElementId: 73,
            //         phoneElementId: 84,
            //         descList: [
            //             {
            //                 descName: 'Meter Recognition Reading',
            //                 sort: 1
            //             }, {
            //                 descName: 'By using AI visual technology to read data from various instruments and meters, if the collected data exceeds the threshold, an alert will be generated.',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 4
            //     },
            //     {
            //         pcElementId: 74,
            //         phoneElementId: 85,
            //         descList: [
            //             {
            //                 descName: 'Leakage Detection',
            //                 sort: 1
            //             }, {
            //                 descName: 'By using AI visual identification technology and other means for leakage detection, the coordinates of the leakage location can be displayed and an early warning can be given.',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 5
            //     },
            //     {
            //         pcElementId: 75,
            //         phoneElementId: 86,
            //         descList: [
            //             {
            //                 descName: 'Unmanned Autonomous Inspection',
            //                 sort: 1
            //             }, {
            //                 descName: 'No manual intervention is required, automatic inspection, manual inspection, abnormal inspection and automatic inspection along the track can be realized.',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 6
            //     },
            //     {
            //         pcElementId: 76,
            //         phoneElementId: 87,
            //         descList: [
            //             {
            //                 descName: 'Intelligent Obstacle Avoidance Function',
            //                 sort: 1
            //             }, {
            //                 descName: 'Equipped with obstacle avoidance sensors, safe operation, obstacle avoidance and early warning are achieved through Lidar and anti-collision sensors.',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 7
            //     },
            //     {
            //         pcElementId:77,
            //         phoneElementId: 88,
            //         descList: [
            //             {
            //                 descName: 'Infrared Temperature Measurement of Equipment',
            //                 sort: 1
            //             }, {
            //                 descName: 'monoxide, flammable gases, hydrogen sulfide and other flammable, explosive, toxic and harmful gases',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 8
            //     },
            //     {
            //         pcElementId: 78,
            //         phoneElementId: 89,
            //         descList: [
            //             {
            //                 descName: 'Ambient Gas Monitoring',
            //                 sort: 1
            //             }, {
            //                 descName: 'Equipped with an infrared thermal imager, it can collect the surface temperature of the equipment. Eearly warning for equipment temperature anomalies',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 9
            //     },
            //     {
            //         pcElementId:79,
            //         phoneElementId: 90,
            //         descList: [
            //             {
            //                 descName: 'Explosion-proof Track-hanging Inspection Robot',
            //                 sort: 0
            //             },
            //             {
            //                 descName: 'Specification',
            //                 sort: 1
            //             }, {
            //                 descName: 'Dimension (L×W×H)',
            //                 sort: 2
            //             },
            //             {
            //                 descName: 'Explosion-proof Grade',
            //                 sort: 3
            //             },
            //             {
            //                 descName: 'Working Environment Temperature',
            //                 sort: 4
            //             },
            //             {
            //                 descName: 'Gradeability',
            //                 sort: 5
            //             },
            //             {
            //                 descName: 'Charging Time',
            //                 sort: 6
            //             },
            //             {
            //                 descName: 'Positioning Mode',
            //                 sort: 7
            //             },
            //             {
            //                 descName: 'Curb Weight',
            //                 sort: 8
            //             },
            //             {
            //                 descName: 'Protection Level',
            //                 sort: 9
            //             }, {
            //                 descName: 'Walking Speed',
            //                 sort: 10
            //             },
            //             {
            //                 descName: 'Brake Distance',
            //                 sort: 11
            //             },
            //             {
            //                 descName: 'Battery Endurance',
            //                 sort: 12
            //             },
            //             {
            //                 descName: 'Repetitive Navigation Positioning Accuracy',
            //                 sort: 13
            //             }, {
            //                 descName: '640mm×300mm×460mm',
            //                 sort: 14
            //             },
            //             {
            //                 descName: 'Ex d IIB T4 Gb',
            //                 sort: 15
            //             },
            //             {
            //                 descName: '-20°C~+60°C',
            //                 sort: 16
            //             },
            //             {
            //                 descName: '≤30°',
            //                 sort: 17
            //             },
            //             {
            //                 descName: '≤3h',
            //                 sort: 18
            //             },
            //             {
            //                 descName: 'Odometer + RFID Calibration',
            //                 sort: 19
            //             },
            //             {
            //                 descName: '≤80kg',
            //                 sort: 20
            //             },
            //             {
            //                 descName: 'IP67',
            //                 sort: 21
            //             }, {
            //                 descName: '0-1.5m/s,Speed Adjustable',
            //                 sort: 22
            //             },
            //             {
            //                 descName: '600mm',
            //                 sort: 23
            //             },
            //             {
            //                 descName: '≥6 km',
            //                 sort: 24
            //             },
            //             {
            //                 descName: '≤1cm',
            //                 sort: 25
            //             }],
            //         type: 'image',
            //         sort: 10
            //     },
            //     {
            //         pcElementId: 80,
            //         phoneElementId: 91,
            //         descList: [
            //             {
            //                 descName: 'Explosion-proof Track-hanging Inspection Robot',
            //                 sort: 1
            //             }, {
            //                 descName: '60° Endless View',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 11
            //     },
            //     {
            //         pcElementId:9,
            //         phoneElementId:9,
            //         descList: [
            //             {
            //                 descName: 'Product Manual',
            //                 sort: 1
            //             }],
            //         type: 'image',
            //         sort: 12
            //     }
            // ],
            elmentList:[],
            aboutswiper: null,
        }
    },
    created() {
        //      this.$store.dispatch("pageset/getbannerIndex", 0);
        //         this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        this.$store.dispatch("pageset/getbannerIndex", 0);
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
                this.aboutSwiper();
                if (!this.$store.state.pageset.pcshow) {
                    let video = document.getElementById('videoOne');
                    video.play();
                  }
            }, 100);
        });
    },
    // 销毁滚轮事件
    destroyed() {
        // 销毁swiper
        this.aboutswiper.destroy();
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        // 初始化轮播图
        aboutSwiper() {
            this.aboutswiper = new Swiper('.mySwiper', {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                autoplay: true,
                speed: 2000,
                loop: true,
                effect: 'fade',//切换效果
            });
        },

        openPdf() {
             this.$router.push('/pdfView')
        },
    }
}